// ============================================== VENDOR
/* Slick SLIDER */
$slick-loader-path: "/node_modules/slick-carousel/slick/" !default;
$slick-font-path: "/node_modules/slick-carousel/slick/fonts/" !default;

@import 'slick-carousel/slick/slick.scss';
@import 'slick-carousel/slick/slick-theme.scss';


/* BOOTSTRAP */
@import '~bootstrap/scss/bootstrap';

/* FEATHER ICONS */
@import 'feather-icons';

/* HOVER.CSS */
@import '~hover.css/scss/hover.scss';

/* Date range picker */
// @import "daterangepicker/daterangepicker";
/* Date range picker */

// @import 'datepicker-full/datepicker-full.min'


// @import 'vanillajs-datepicker/sass/datepicker';
// @import 'vanillajs-datepicker/Datepicker';
// @import '../node_modules/vanillajs-datepicker/sass/mixins';
